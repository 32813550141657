import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {IconDefinition} from '@ant-design/icons-angular';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NZ_I18N, ru_RU} from 'ng-zorro-antd/i18n';
import {registerLocaleData} from '@angular/common';
import ru from '@angular/common/locales/ru';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NzMessageModule } from 'ng-zorro-antd/message';
import {
  BgColorsOutline,
  CarOutline,
  DeleteOutline,
  EditOutline,
  EnterOutline,
  LockOutline,
  PlusCircleOutline,
  PoweroffOutline,
  UnorderedListOutline,
  UserOutline,
  GatewayOutline,
  CheckOutline
} from '@ant-design/icons-angular/icons';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzMessageService} from 'ng-zorro-antd/message';
import {ParamInterceptor} from './api.interceptor';
const icons: IconDefinition[] = [
  UserOutline,
  LockOutline,
  EnterOutline,
  UnorderedListOutline,
  CarOutline,
  PlusCircleOutline,
  PoweroffOutline,
  EditOutline,
  DeleteOutline,
  CheckOutline,
  GatewayOutline,
  BgColorsOutline];
registerLocaleData(ru);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NzMessageModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzIconModule.forRoot(icons),
  ],
  providers: [{provide: NZ_I18N, useValue: ru_RU}, {provide: HTTP_INTERCEPTORS, useClass: ParamInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
