import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IToken} from './interfaces/token.interface';

@Injectable({providedIn: 'root'})
export class TokenService {
  token: BehaviorSubject<IToken> = new BehaviorSubject<IToken>(null);

  constructor() {
    const localStorageToken = JSON.parse(localStorage.getItem('ACCESS_TOKEN'));
    if (localStorageToken) {
      this.token.next(localStorageToken);
    }
  }

  getToken(): Observable<IToken> {
    return this.token.asObservable();
  }

  setToken(token): void {
    localStorage.setItem('ACCESS_TOKEN', JSON.stringify(token));
    this.token.next(token);
  }
}
