import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Router} from '@angular/router';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ParamInterceptor implements HttpInterceptor {
  constructor(private msg: NzMessageService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error.status === 401) {
          this.msg.error('Вы не авторизованы или ваша сессия истекла');
          this.router.navigate(['/']);
          localStorage.clear();
        } else {
          this.msg.error(error.message);
        }
        return throwError(error);
      })
    );
  }
}
