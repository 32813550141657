import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {TokenService} from '../token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private tokenService: TokenService, private router: Router) {
  }

  canActivate(): Observable<boolean> {
    return this.tokenService.getToken().pipe(
      map(token => !!token),
      tap(isLoggedIn => {
      if (!isLoggedIn) {
        this.router.navigate(['']);
      }
    }));
  }

}
